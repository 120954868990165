import _ from "lodash";
import ObservationDetails from "../views/observation-details.vue";

const Observations = () => import("../views/observations.vue");
const AdminPage = () => import("../views/admin-page.vue");
const NeoBeatEpisodes = () => import("../views/neobeat-episodes.vue");
const MoyoEpisodes = () => import("../views/moyo-episodes.vue");
const NeoNatalieSessions = () => import("../views/neonatalie-sessions.vue");
const NeoNatalieSessionDetails = () => import("../views/neonatalie-session-details.vue");
const NeoNatalieLearners = () => import("../views/neonatalie-learners.vue");
const ReportsDetails = () => import("../views/reports-details.vue");
const SiteAdminOverview = () => import("../views/site-admin-overview.vue");
const ManagedUsersOverview = () => import("../views/site-admin-users.vue");
const SiteAdminNewUserPermission = () => import("../views/site-admin-add-userpermission.vue");
const MyProfile = () => import("../views/my-profile.vue");
const NotFound = () => import("../views/not-found.vue");
const SiteSettingsPage = () => import("../views/site-settings-page.vue");
const ScenarioTrainingSessions = () => import("../views/scenario-training-sessions.vue");
const ScenarioTrainingSessionDetails = () => import("../views/scenario-training-session-details.vue");

export const routes = [
  {
    path: "/",
    name: "Observations",
    component: Observations,
    display: "Liveborn observations"
  },
  {
    path: "/observationDetails",
    name: "ObservationDetails",
    component: ObservationDetails,
    display: "Observation details",
    hidden: true,
  },
  {
    path: "/neoBeatEpisodes",
    name: "NeoBeatEpisodes",
    component: NeoBeatEpisodes,
    display: "NeoBeat episodes",
  },
  {
    path: "/moyoEpisodes",
    name: "MoyoEpisodes",
    component: MoyoEpisodes,
    display: "Moyo episodes",
  },
  {
    path: "/liftScenarios",
    name: "LiftScenariosSessions",
    component: ScenarioTrainingSessions,
    display: "LIFT Scenarios"
  },

  {
    path: "/liftScenarios/details",
    name: "LiftScenarioSessionDetails",
    component: ScenarioTrainingSessionDetails,
    display: "LIFT Scenario Session",
    hidden: true,
  },
  {
    path: "/neoNatalieSessions",
    name: "NeoNatalieSessions",
    component: NeoNatalieSessions,
    display: "NeoNatalie Live",
  },
  {
    path: "/neoNatalieSessionDetails",
    name: "NeoNatalieSessionDetails",
    component: NeoNatalieSessionDetails,
    display: "NeoNatalie session details",
    hidden: true,
  },
  {
    path: "/neonatalie-admin",
    name: "NeoNatalieAdmin",
    component: NeoNatalieLearners,
    display: "Manage NeoNatalie learners",
    hidden: true,
  },
  {
    path: "/reportDetails/liveborn",
    name: "LivebornReports",
    component: ReportsDetails,
    display: "Dashboards",
    hidden: true,
  },
  {
    path: "/reportDetails/neonatalie",
    name: "NeoNatalieReports",
    component: ReportsDetails,
    display: "Dashboards",
    hidden: true,
  },
  {
    path: "/reportDetails/*",
    name: "ReportDetails",
    component: NotFound,
    display: "Dashboards",
    hidden: true,
  },
  {
    path: "/siteadmin-overview",
    name: "SiteAdmin",
    component: SiteAdminOverview,
    display: "Permission overview",
    hidden: true,
    meta: {
      require2fa: true,
    },
  },
  {
    path: "/siteadmin-users",
    name: "SiteAdminUsers",
    component: ManagedUsersOverview,
    display: "Managed users overview",
    hidden: true,
    meta: {
      require2fa: true,
    },
  },
  {
    path: "/siteadmin-add-new-user-permission",
    name: "SiteAdminUserPermissions",
    component: SiteAdminNewUserPermission,
    display: "",
    hidden: true,
    meta: {
      require2fa: true,
    },
  },
  {
    path: "/my-profile",
    name: "MyProfile",
    component: MyProfile,
    display: "",
    hidden: true,
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminPage,
    display: "",
    hidden: true,
    meta: {
      require2fa: true,
    },
  },
  {
    path: "/site-settings",
    name: "SiteSettings",
    component: SiteSettingsPage,
    display: "",
    hidden: true,
    // meta: {
    //   require2fa: true,
    // },
  },
];

export const visibleRoutes = _.filter(routes, (route) => !route.hidden);